<template>
  <div class="hello">
    <tiles v-if="isLoggedIn">
      <card-widget
        class="tile is-child"
        type="is-primary"
        icon="account-multiple" :number="pf"
        label="Persoane Fizice"
      />
      <card-widget
        class="tile is-child"
        type="is-primary"
        icon="account-multiple"
        :number="pj"
        label="Persoane Juridice"
      />
    </tiles>

    <section class="section is-large" v-if="!isLoggedIn">
      <h1 class="title">Neautorizat.</h1>
      <h2 class="subtitle">Te rugam sa te autentifici pentru a continua. </h2>
    </section>

    <div v-if="false">Process: {{ process }}</div>
  </div>
</template>

<script>
import Tiles from "./reusable/dashboard/Tiles";
import CardWidget from "./reusable/dashboard/CardWidget";
import { mapGetters } from "vuex";
import { api } from "@/network";

export default {
  name: "HelloWorld",
  components: {
    Tiles,
    CardWidget,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      pf: 0,
      pj: 0,
      process: process.env,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isAuthenticated"
    }),
  },
  mounted() {
    if (this.isLoggedIn) {
      api.get("dashboard-data").then(({ data }) => {
        this.pf = data.pf;
        this.pj = data.pj;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
